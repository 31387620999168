import React from "react";
import "./cart.css";
import { useEffect, useState, useRef } from "react";
import { RestaurantState } from "../context/Context";
import { Api } from "../service/Api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import MobileVerificationModal from "./MobileVerificationModal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LoadScript } from "@react-google-maps/api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactGA from "react-ga4";
import ForgotPasswordModal from "./ForgotPasswordModal";

const Cart = () => {
  const {
    state,
    dispatch,
    totalPackagingCharges,
    setTotalPackagingCharges,
    kitchen,
  } = RestaurantState();
  const { restaurentId, tableId, setRestaurentId } = RestaurantState();
  const [total, setTotal] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  // const [isProcessingCard, setIsProcessingCard] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMobileVerification, setShowMobileVerification] = useState(false);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [buildingOption, setBuildingOption] = useState([]);
  const [buildingOptionValue, setBuildingOptionValue] = useState();
  // const [totalPackagingCharges, setTotalPackagingCharges] = useState(0);
  const [totalDeliveryCharges, setTotalDeliveryCharges] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPasswordField, setShowForgotPasswordField] = useState(false);
  const [isOpen, setIsOpen] = useState(true); // Default to true
  const [showModal, setShowModal] = useState(false);
  const [platformFeesPerc, setPlatformFeesPerc] = useState(0);
  const [platformFees, setPlatformFees] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0); // Final total amount
  const [isFetchingFees, setIsFetchingFees] = useState(true); // Separate state for fetching fees

  const history = useNavigate();
  const backURL = localStorage.getItem("slug")
    ? "/" + localStorage.getItem("slug")
    : "/";
  const mobile_no = localStorage.getItem("mobile_no");

  useEffect(() => {
    const checkRestaurantStatus = async () => {
      try {
        const response = await Api.getOrderConfig();
        setIsOpen(response?.data?.isOpen);
        setPlatformFeesPerc(response?.data?.platform_fees ?? 0);
        setIsFetchingFees(false);
        if (!response?.data?.isOpen) {
          setShowModal(true); // Show modal if closed
        }
      } catch (error) {
        console.error("Error checking time:", error);
      }
    };

    checkRestaurantStatus();
  }, []);

  useEffect(() => {
    const subTotal =
      Number(total) + Number(totalPackagingCharges) + Number(deliveryCharge);

    // Calculate platform fees based on the percentage
    const calculatedPlatformFees = (subTotal * platformFeesPerc) / 100;
    setPlatformFees(calculatedPlatformFees);

    // Add platform fees to get the final total amount
    setTotalAmount(subTotal + calculatedPlatformFees);
  }, [total, totalPackagingCharges, deliveryCharge, platformFeesPerc]);

  // load razorpay script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/cart", title: "Cart" });
    const storedArrayString = localStorage.getItem("cartState");
    dispatch({
      type: "SET_CART_ITEMS",
      payload: storedArrayString ? JSON.parse(storedArrayString) : [],
    });

    const savedCharges = localStorage.getItem("totalPackagingCharges");
    if (savedCharges) {
      setTotalPackagingCharges(Number(savedCharges));
    }
  }, []);

  // Load packaging charges from localStorage on component mount
  useEffect(() => {
    if (kitchen.length > 0 && state.length > 0) {
      const uniqueKitchenIds = new Set(state.map((item) => item.kitchen_id));

      // Sum packaging charges for unique kitchen IDs present in state
      const totalCharges = Array.from(uniqueKitchenIds).reduce(
        (acc, kitchenId) => {
          const matchingKitchen = kitchen.find((k) => k.id === kitchenId);
          return (
            acc +
            (matchingKitchen
              ? Number(matchingKitchen.packaging_charge) || 0
              : 0)
          );
        },
        0
      );

      setTotalPackagingCharges(totalCharges.toFixed(2));
      localStorage.setItem("totalPackagingCharges", totalCharges.toFixed(2));
    }
  }, [state, kitchen]);

  const handleOverlayClick = (e) => {
    // Ensure the click is only registered on the background, not inside the modal content
    if (e.target.classList.contains("modal-overlay")) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (isModalOpen) {
        setIsModalOpen(false);
      }
    };

    // Push an initial state to history to trap the back button
    window.history.pushState(null, "");

    // Add the popstate event listener
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Remove the event listener on component unmount
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isModalOpen]);

  const orderDetails1 = state.map((v) => ({
    ...v,
    table_id: tableId,
    menu_id: v.id,
    status: 0,
    tax: 0,
    sub_total: parseFloat(v.price) * parseInt(v.quantity),
    total: parseFloat(v.price) * parseInt(v.quantity),
    item_name: v.item_name,
  }));
  const orderDetails = orderDetails1.map(({ id, name, price, ...rest }) => {
    return rest;
  });
  useEffect(() => {
    setTotal(
      state.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0)
    );
    // setTotalPackagingCharges(
    //   state.reduce(
    //     (acc, curr) =>
    //       acc +
    //       (curr.packaging_charge
    //         ? Number(curr.packaging_charge) * (curr.quantity || 0)
    //         : 0),
    //     0
    //   )
    // );
    setTotalDeliveryCharges(
      state.reduce(
        (acc, curr) =>
          acc +
          (curr.delivery_charge
            ? Number(curr.delivery_charge) * (curr.quantity || 0)
            : 0),
        0
      )
    );
  }, [state]);

  function createOrder(payment_method, response) {
    setIsProcessing(true);
    return new Promise((resolve, reject) => {
      const data = {
        restaurent_id: restaurentId
          ? restaurentId
          : localStorage.getItem("restaurant_id"),
        payment_method: payment_method,
        mobile_no: mobile_no,
        address: address,
        lat: lat,
        lng: lng,
        sub_total: total,
        packaging_charge: totalPackagingCharges,
        delivery_charge: deliveryCharge,
        tax: 0,
        total: total,
        status: 0,
        order_details: orderDetails,
        razorpay_payment_id:
          response && response !== "" ? response.razorpay_payment_id : "",
        razorpay_order_id:
          response && response !== "" ? response.razorpay_order_id : "",
        razorpay_signature:
          response && response !== "" ? response.razorpay_signature : "",
        payment_received: 0,
        building_id: buildingOptionValue?.id,
      };
      Api.processCheckOut(data)
        .then((res) => {
          if (res.data?.meta?.code === 200) {
            var orderIds = localStorage.getItem("orderIds");
            if (orderIds) {
              orderIds = JSON.parse(orderIds);
              orderIds.push(res.data.data.id);
              localStorage.setItem("orderIds", JSON.stringify(orderIds));
            } else {
              orderIds = [];
              orderIds.push(res.data.data.id);
              localStorage.setItem("orderIds", JSON.stringify(orderIds));
            }
            if (payment_method === 1) {
              dispatch({
                type: "EMPTY_CART",
              });
              history("/" + localStorage.getItem("slug"));
              toast("Order placed successfully!");
              localStorage.setItem("address", address);
              localStorage.setItem("building", buildingOptionValue?.id);
              resolve();
            } else {
              resolve();
            }
          } else if (res.data?.meta?.code === 400) {
            // localStorage.removeItem("mobile_no");
            history("/" + localStorage.getItem("slug"));
            toast(res.data.meta?.message);
            reject("Something went wrong.");
          } else {
            toast.error(res.data?.message);
            reject("Something went wrong.");
          }
          // toast.error(res.data.message);
        })
        .catch((e) => {
          reject(e.message);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    });
  }

  function generateOrderId() {
    // Generate a random string of 5 characters
    const randomString = Math.random().toString(36).substr(2, 5);

    // Get the current timestamp in milliseconds
    const timestamp = Date.now();

    // Combine the random string and timestamp to create a unique order ID
    const orderId = `ORDER-₹{timestamp}-₹{randomString}`;

    return orderId;
  }
  const handlePayClick = (method) => {
    setPaymentMethod(method);
    if (!mobile_no) {
      setShowMobileVerification(true);
    } else {
      setIsModalOpen(true);
    }
  };
  // Handle successful mobile verification
  const handleVerified = (verifiedMobile) => {
    localStorage.setItem("mobile_no", verifiedMobile);
    setShowMobileVerification(false);
    setIsModalOpen(true);

    const userAddress = localStorage.getItem("address");
    const building = localStorage.getItem("building");
    setAddress(userAddress);
    setBuildingOptionValue({
      id: building,
      name: '',
    });

    // Setting up the cart data to update header
    const storedArrayString = localStorage.getItem("cartState");
    dispatch({
      type: "SET_CART_ITEMS",
      payload: storedArrayString ? JSON.parse(storedArrayString) : [],
    });
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    // setAddress("");
    setPaymentMethod(null);
  };

  const handleSubmitPayment = async () => {
    if (
      address.trim() !== "" &&
      buildingOptionValue &&
      buildingOptionValue.id
    ) {
      if (paymentMethod === "cash") {
        setIsLoading(true);

        //  TODO::Razorpay

        // const data = await Api.processOnLine({
        //   amount: total, // set as per your requirement
        //   orderId: generateOrderId(), // generate a unique order ID
        // });

        // createOrder(1, { razorpay_order_id: data?.data?.orderId });
        createOrder(1, { razorpay_order_id: "" });
        setIsLoading(false);
      } else if (paymentMethod === "online") {
        handlePayNowClick();
      }
      handleModalClose();
    } else {
      toast.error("Please Select building and enter address.");
    }
  };

  const handlePayNowClick = async () => {
    setIsLoading(true);
    Api.processOnLine({
      amount: totalAmount, // set as per your requirement
      orderId: generateOrderId(), // generate a unique order ID
    })
      .then((res) => {
        handleRazorpayPayment(res.data.orderId);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateOrder = (response) => {
    setIsProcessing(true);
    const data = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
      payment_received: 1,
    };
    Api.updateOrderAndProceed(data)
      .then((res) => {
        if (res.data.meta.code === 200) {
          dispatch({
            type: "EMPTY_CART",
          });
          history("/" + localStorage.getItem("slug"));
          toast("Order placed successfully!");
        }
        toast.error(res.data.message);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleRazorpayPayment = async (order_id) => {
    createOrder(2, {
      razorpay_payment_id: "",
      razorpay_order_id: order_id,
      razorpay_signature: "",
    })
      .then(() => {
        const options = {
          key: process.env.REACT_APP_RAZOR_PAY_KEY_LIVE,
          amount: totalAmount * 100,
          currency: "INR", // change as per your requirement
          name: "Tecoreng",
          description: "Payment for your order",
          order_id: order_id,
          handler: function (response) {
            updateOrder(response);
            // dispatch({
            //   type: "EMPTY_CART",
            // });
            // history("/" + localStorage.getItem("slug"));
            // toast("Order placed succesfully!");
          },
          theme: {
            color: "#F37254", // change as per your requirement
          },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleSelect = async (address) => {
    setAddress(address);
    setLat(0);
    setLng(0);
    // try {
    //   const results = await geocodeByAddress(address);
    //   const { lat, lng } = await getLatLng(results[0]);
    //   setLat(lat);
    //   setLng(lng);
    // } catch (error) {
    //   console.error("Error retrieving location:", error);
    // }
  };

  useEffect(() => {
    const userAddress = localStorage.getItem("address");
    const building = localStorage.getItem("building");
    setAddress(userAddress);
    setBuildingOptionValue({
      id: building,
      name: '',
    });
    const restaurant_id = localStorage.getItem("restaurant_id");
    if (restaurant_id && restaurant_id !== 0) {
      Api.getBuildings(restaurant_id).then((res) => {
        if (res.data.meta.code === 200) {
          setBuildingOption(res.data?.data);
          //   dispatch({
          //     type: "EMPTY_CART",
          //   });
          //   history("/" + localStorage.getItem("slug"));
          //   toast("Order placed successfully!");
        }
        toast.error(res.data.message);
      });
    }
  }, []);

  const getItemQuantity = (itemId) => {
    const foundItem = state.find((cartItem) => cartItem.id === itemId);
    return foundItem ? foundItem.quantity : 0;
  };

  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    const selectedOption = buildingOption.find(
      (option) => option.id.toString() === selectedId
    );

    if (selectedOption) {
      setBuildingOptionValue({
        id: selectedOption.id.toString(),
        name: selectedOption.name,
      });
      setDeliveryCharge(selectedOption.delivery_charge ?? 0);
    } else {
      setBuildingOptionValue(null);
      setDeliveryCharge(0);
    }
  };

  return (
    <>
      <div className="mt-1 padding-bottom-3rem min-h-[90dvh] h-full overflow-y-auto w-full flex justify-center">
        <div className="cart d-flex flex-column max-h-[calc(95vh_-0px)] overflow-y-auto px-2 md:px-4 py-2 w-full max-w-[850px]">
          <div className="py-2 text-red-700 font-bold text-center">
            {!isOpen &&
              "Note: We are not delivering currently. Our delivery hours are 9 AM to 9 PM, Monday to Saturday"}
          </div>
          <div className="flex items-center justify-between px-2 mb-4">
            <div className="flex-1">
              <div
                className="rounded h-[35px] w-[35px] pt-[1px] "
                style={{ backgroundColor: "var(--primary-color)" }}
              >
                <Link to={backURL}>
                  <FaArrowLeft color="black" fontSize="17px" className="m-2" />
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-center pt-[5px] text-lg font-bold">
              CHECKOUT
            </div>
            <div className="flex-1"></div>
          </div>
          {total === 0 ? (
            <>
              <div className="text-center mt-4 text-gray-500 dark:text-gray-400">
                Your cart is empty
              </div>
            </>
          ) : (
            ""
          )}
          {state.map((item) => {
            const qty = getItemQuantity(item.id);
            return (
              <div
                className="bg-white rounded-lg shadow-md p-2 mb-3 cursor-pointer transition duration-300 ease-in-out hover:scale-105"
                key={item.id}
              >
                <div className="w-full flex">
                  <div className="w-full max-w-[120px] h-[90px] shadow-md hover:shadow-lg focus:shadow-xl outline-none">
                    <img
                      className="w-full h-full cart-item-img object-cover"
                      src={item.image}
                      alt=""
                      onError={(e) => (e.target.src = "no-image.png")}
                    />
                  </div>
                  <div className="cartItem-details d-flex flex-column justify-content-center ms-2 w-full">
                    <div className="font-semibold text-[14px]">
                      {item.item_name}
                    </div>
                    <div
                      className={`${
                        item.packaging_charge > 0 ? "sm:flex" : "flex"
                      }  justify-between items-center`}
                    >
                      <div className="font-semibold text-[16px] mt-[8px] items-center text-[#28a745]">
                        ₹
                        {qty > 0
                          ? parseFloat(item.price * qty).toFixed(2)
                          : parseFloat(item.price).toFixed(2)}{" "}
                        {/* {item.packaging_charge && item.packaging_charge > 0 ? (
                          <span className="text-[12px] leading-tight items-center font-semibold">
                            {" "}
                            + ₹
                            {parseFloat(item.packaging_charge * qty).toFixed(2)}
                            <span className="text-[12px] text-customText leading-none">
                              {" "}
                              (Packaging Charges)
                            </span>
                          </span>
                        ) : null} */}
                      </div>
                      <div className="flex justify-end">
                        <div className="itemDetails-qty-controls">
                          <div
                            className="itemDetails-qty-button"
                            onClick={() => {
                              dispatch({
                                type: "DECREASE_QTY",
                                payload: item,
                              });
                            }}
                          >
                            -
                          </div>
                          <div className="itemDetails-qty-display">
                            {" "}
                            {item.quantity}
                          </div>
                          <div
                            className="itemDetails-qty-button"
                            onClick={() => {
                              dispatch({
                                type: "INCREASE_QTY",
                                payload: item,
                              });
                            }}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {total > 0 ? (
            <div className="bg-[#ffffff] rounded-lg shadow-md p-2 mt-2">
              <div className="flex justify-content-between">
                <div>Sub Total</div>
                <div>{parseFloat(total).toFixed(2)}</div>
              </div>
              {/*{deliveryCharge !== undefined && deliveryCharge > 0 && (*/}
                <div className="flex justify-content-between">
                  <div>Delivery Charges </div>
                  <div>{deliveryCharge && parseFloat(deliveryCharge) > 0 ? parseFloat(deliveryCharge).toFixed(2) : '0.00'}</div>
                </div>
              {/*)}*/}
              {/*{totalPackagingCharges !== undefined &&*/}
              {/*  totalPackagingCharges > 0 && (*/}
                  <div className="flex justify-content-between">
                    <div>Packaging / Processing Fees</div>
                    <div>{totalPackagingCharges && parseFloat(totalPackagingCharges) > 0 ? parseFloat(totalPackagingCharges).toFixed(2) : '0.00'}</div>
                  </div>
                {/*)}*/}
              {/*{platformFees !== undefined && platformFees > 0 && (*/}
                <div className="flex justify-content-between">
                  <div>Platform Fees</div>
                  <div>{platformFees && parseFloat(platformFees) > 0 ? parseFloat(platformFees).toFixed(2) : '0.00'}</div>
                </div>
              {/*)}*/}
              {/* <div className="flex justify-content-between">
                <div>Tax</div>
                <div>0.00</div>
              </div> */}
              <div className="bg-[#e1e3ec] my-2 w-full h-[1px]"></div>
              <div className="flex justify-content-between font-semibold">
                <div>Total</div>
                <div>₹{parseFloat(totalAmount).toFixed(2)}</div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="rounded mb-2 mt-4 bottom-[40px]">
            {total > 0 ? (
              <>
                <div className="">
                  {isProcessing ? (
                    <div
                      role="status"
                      className="d-flex justify-center mr-2 mb-2"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        className={`w-100 from-green-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 ${
                          !isOpen || isFetchingFees
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        style={{ backgroundColor: "var(--primary-color)" }}
                        onClick={() => handlePayClick("cash")}
                        disabled={!isOpen || isFetchingFees}
                      >
                        Pay On Delivery
                      </button>
                      {/*//  TODO::Razorpay*/}

                      {/*<button*/}
                      {/*  type="button"*/}
                      {/*  className={`w-100 from-green-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 ${*/}
                      {/*    !isOpen || isFetchingFees*/}
                      {/*      ? "opacity-50 cursor-not-allowed"*/}
                      {/*      : ""*/}
                      {/*  }`}*/}
                      {/*  style={{ backgroundColor: "var(--primary-color)" }}*/}
                      {/*  onClick={() => handlePayClick("online")}*/}
                      {/*  disabled={!isOpen || isFetchingFees}*/}
                      {/*>*/}
                      {/*  Pay Online*/}
                      {/*</button>*/}
                    </div>
                  )}
                  {/* {
                    isProcessing ? (
                      <div
                        role="status"
                        className="d-flex justify-center mr-2 mb-2"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="w-100 from-green-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                        style={{ backgroundColor: "var(--primary-color)" }}
                        onClick={() => handlePayClick("online")}
                      >
                        Pay Online
                      </button>
                    )
                  } */}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Modal for mobile number input */}
        {isModalOpen && (
          <div className="modal-overlay" onClick={(e) => handleOverlayClick(e)}>
            <div className="modal-content">
              <h2>Address( {localStorage.getItem("restaurant_name")} )</h2>
              {/* <LoadScript googleMapsApiKey="AIzaSyBrS7wOJMe1QsgFMfbvY-IWBNaFZ3fkdHI" libraries={["places"]}>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="autocomplete-wrapper">
                      <input
                        {...getInputProps({
                          placeholder: "Search Address...",
                          className: "modal-input",
                        })}
                      />

                      <div className="suggestions-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const suggestionStyle = {
                            backgroundColor: suggestion.active ? "#e6e6e6" : "#ffffff",
                            padding: "10px",
                            cursor: "pointer",
                            borderBottom: "1px solid #ddd",
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style: suggestionStyle })}
                              className="suggestion-address-item"
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </LoadScript>
              */}
              <div className="block w-full md:flex gap-4">
                <div className="block w-full md:w-[20%]">
                  <select
                    value={buildingOptionValue?.id}
                    onChange={handleSelectChange}
                    className="sort-dropdown"
                    style={{ width: "100%", margin: "10px 0", padding: "10px" }}
                  >
                    <option value="">Select Building</option>
                    {buildingOption.map((option) => (
                      <option value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
                {/* <Col xs={2}>
                </Col> */}
                <div className="block w-full md:flex-1">
                  <input
                    type="text"
                    className="modal-input"
                    placeholder="Enter Office Number and Building Block"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              {/* Display message when an option is selected */}
              {deliveryCharge !== undefined && deliveryCharge > 0 && (
                <p className="text-sm text-left font-semibold mt-2">
                  Note: A delivery fee of ₹{deliveryCharge} will be applied for
                  deliveries to {buildingOptionValue.name}.
                </p>
              )}
              <button
                className={`btn-confirm ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleSubmitPayment}
                disabled={isLoading}
              >
                Confirm
              </button>
              <button className="btn-mclose" onClick={handleModalClose}>
                Cancel
              </button>
            </div>
          </div>
        )}

        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-xl font-semibold mb-4">Notice</h2>
              <p className="mb-4">
                We are not delivering right now, the restaurant is closed.
              </p>
              <button
                onClick={() => setShowModal(false)}
                style={{ backgroundColor: "var(--primary-color)" }}
                className="font-medium px-4 py-2 rounded-md w-full"
              >
                OK
              </button>
            </div>
          </div>
        )}
        {/* Mobile Verification Modal */}
        <MobileVerificationModal
          show={showMobileVerification}
          onClose={() => setShowMobileVerification(false)}
          onVerified={handleVerified}
          showForgotField={() => setShowForgotPasswordField(true)}
        />
        <ForgotPasswordModal
          show={showForgotPasswordField}
          onClose={() => setShowForgotPasswordField(false)}
        />
      </div>
    </>
  );
};

export default Cart;
