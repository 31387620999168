import React, { useEffect, useState } from "react";
import "./variables.css";
import "./App.css";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Cart from "./components/Cart";
import { PastOrder } from "./components/PastOrder";
import Checkout from "./components/Checkout";
import Menu from "./components/Menu";
import ItemDetail from "./components/ItemDetail";
import ReviewOrder from "./components/ReviewOrder";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import AboutUs from "./components/AboutUs";
import ShoppingDeliveryPolicy from "./components/ShoppingDeliveryPolicy";
import CancellationRefundPolicy from "./components/CancellationRefundPolicy";
import ContactUS from "./components/ContactUS";
import MyMenuCafe from "./components/MyMenuCafe";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { IoMdCloseCircle } from "react-icons/io";
import { isMobile, isTablet } from "react-device-detect";
import { RestaurantState } from "./context/Context";
import ScrollToTop from "./components/common/ScrollToTop";
import ReactGA from "react-ga4";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import OrderDetailsWithQR from "./components/OrderDetailsWithQR/OrderDetailsWithQR";

function App() {
  const [isVisible, setIsVisible] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const { state } = RestaurantState();
  const totalQty = state.reduce((sum, item) => sum + (item?.quantity || 0), 0);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC);
    // Check if the user has previously closed the install button
    const closed = sessionStorage.getItem("showPWAButton");

    // if user previously closed the button and also we not have access of pwa feature then hide button
    if (closed || !deferredPrompt) {
      setIsVisible(false); // Hide button if closed before
    }

    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default install banner
      e.preventDefault();
      setDeferredPrompt(e); // Save the event for later use

      // if user not closed button and we have access then
      if (!closed) return setIsVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  // Handle the Install button click
  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user's response
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }

        // Reset the deferred prompt
        setDeferredPrompt(null);
        setIsVisible(false); // Hide the install button after prompt
      });
    }
  };

  const handleCloseButton = () => {
    // Set sessionStorage flag when button is closed
    sessionStorage.setItem("showPWAButton", "true");
    setIsVisible(false);
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <Header />
      <div className="p-0 m-auto">
        <ScrollToTop />
        <Routes>
          <Route path="/:slug" exact element={<Menu />} />

          <Route path="/" exact key="second-api" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          {/* <Route path="/menu" element={<Menu />} /> */}
          <Route path="/itemdetail" element={<ItemDetail />} />
          <Route path="/revieworder" element={<ReviewOrder />} />
          <Route path="/pastOrders" element={<PastOrder />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/shipping-policy" element={<ShoppingDeliveryPolicy />} />
          <Route path="/refund-policy" element={<CancellationRefundPolicy />} />
          <Route path="/contact-us" element={<ContactUS />} />
          <Route path="/order-details/:order_id" element={<OrderDetailsWithQR />} />
        </Routes>
      </div>
      <Footer />
      <ToastContainer />
      {isVisible && (isMobile || isTablet) && (
        <div
          className={`fixed right-0 w-full px-2 ${
            totalQty === 0 ? "bottom-6" : "bottom-20"
          }`}
        >
          <IoMdCloseCircle
            className="ml-auto text-black text-xl cursor-pointer"
            onClick={handleCloseButton}
          />

          <button
            onClick={handleInstallClick}
            className=" w-full px-4 py-2 bg-[#ffc93d] rounded-xl text-black cursor-pointer font-semibold"
          >
            Add To Homescreen
          </button>
        </div>
      )}
    </GoogleReCaptchaProvider>
  );
}

export default App;
