// ForgotPasswordModal.js
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Api } from "../service/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ForgotPasswordModal = ({ show, onClose, onVerified }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [countdown, setCountdown] = useState(0);
  const history = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const modalDefaultState = () => {
    setMobileNumber("");
    setError("");
    setLoading(false);
    setIsCodeSent(false);
    setIsVerifying(false);
    setPassword("");
    onClose();
    setVerificationCode("");
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  // Send verification code
  const handleSendCode = async (e) => {
    setCountdown(60);
    e.preventDefault();

    if (!executeRecaptcha) {
      console.error("reCAPTCHA not available.");
      // alert("reCAPTCHA not available.");
      return;
    }
    // Execute reCAPTCHA and get the token
    const token = await executeRecaptcha("login");
    if (!token) {
      console.error("Failed to get reCAPTCHA token.");
      return;
    }
    try {
      setLoading(true);
      Api.checkNumberExist(mobileNumber).then((res) => {
        if (!res.data?.data) setError(res.data?.meta.message || "Error");
        if (res.data?.data) {
          // that means number is exist in customer table
          const data = {
            phone_number: mobileNumber,
            token: token,
          };
          Api.sendOTP(data).then((res) => {
            if (!res.data.success) {
              setError(res.data.message);
              setLoading(false);
            }
            if (res.data.meta.code === 301) {
              history("/" + res.data.meta.url);
            }
            if (res.data.meta.code === 200) {
              setIsCodeSent(true);
              setError("");
              toast("OTP sent successfully!");
            } else {
              setError(res.data.meta.message || "Error sending OTP.");
              setCountdown(0);
            }
            setLoading(false);
          });
        } else {
          // if number is not exist in customer tbale
          setLoading(false);
        }
      });
    } catch (err) {
      setError("Error sending verification code.");
      setLoading(false);
      console.error(err);
    }
  };

  // Verify the code
  const handleVerifyCode = async () => {
    try {
      setLoading(true);
      setIsVerifying(true);
      const OTPData = {
        phone_number: mobileNumber,
        code: verificationCode,
        password: password,
      };
      Api.verifyOTP(OTPData).then((res) => {
        if (res.data.meta.code === 301) {
          history("/" + res.data.meta.url);
        }
        if (res.data.meta.code === 200) {
          modalDefaultState();
          toast("Password updated successfully!");
        } else {
          setError(res.data.meta.message || "Invalid OTP. Please try again.");
        }
        setLoading(false);
      });
    } catch (err) {
      setError("Error verifying the code.");
      setLoading(false);
      console.error(err);
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendClick = () => {
    if (countdown === 0) {
      handleSendCode(); // Call API or resend logic
    }
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    // Remove all non-numeric characters (including spaces)
    value = value.replace(/\D/g, "");
    setMobileNumber(value);

    if (/^[1-4]/.test(value)) {
      setError("Mobile number cannot start with 1, 2, 3, or 4.");
    } else if (!/^[1-4]/.test(value) && value.length <= 10) {
      setError("");
    } else if (value.length >= 10) {
      if (!/^[1-4]/.test(value)) {
        setError("");
      } else {
        setError("Mobile number cannot start with 1, 2, 3, or 4.");
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Forgot Password</h2>
          <input
            type="tel"
            className="modal-input"
            placeholder="e.g., 1234567890"
            value={mobileNumber}
            maxLength={10}
            onChange={handleInputChange}
            onBlur={(e) => {
              if (
                /^[1-4]/.test(e.target.value && e.target.value.length === 10)
              ) {
                setError("Mobile number cannot start with 1, 2, 3, or 4.");
              } else if (
                e.target.value.length !== 10 &&
                !/^[1-4]/.test(e.target.value)
              ) {
                setError("Mobile number must be 10 digits.");
              }
            }}
            disabled={isCodeSent}
          />
          {isCodeSent && (
            <>
              <input
                type="text"
                className="modal-input"
                placeholder="Enter OTP"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                  setError("");
                }}
                required={true}
              />
              <input
                type="password"
                className="modal-input"
                placeholder="Enter New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />
            </>
          )}
          {error && <p className="text-danger mt-2">{error}</p>}
          {loading ? (
            <Loader />
          ) : !isCodeSent ? (
            <button
              className={`btn-confirm ${
                error ? "hover:cursor-not-allowed text-red-500" : ""
              }`}
              onClick={handleSendCode}
              disabled={mobileNumber.length < 10 || error}
            >
              Verify
            </button>
          ) : (
            <div>
              <button
                className={`btn-confirm ${
                  verificationCode.length === 0 ||
                  password.length === 0 ||
                  error
                    ? "hover:cursor-not-allowed"
                    : ""
                }`}
                onClick={handleVerifyCode}
                disabled={
                  isVerifying ||
                  verificationCode.length === 0 ||
                  password.length === 0 ||
                  error
                }
              >
                {isVerifying ? "Verifying..." : "Verify Code"}
              </button>
              <button
                className={`btn-confirm ${
                  mobileNumber.length < 10 || countdown > 0
                    ? "hover:cursor-not-allowed opacity-75 text-gray-500"
                    : ""
                }`}
                onClick={handleResendClick}
                disabled={mobileNumber.length < 10 || countdown > 0}
              >
                {countdown > 0 ? `Resend OTP in ${countdown}s` : "Resend OTP"}
              </button>
            </div>
          )}
          <button className="btn-mclose" onClick={() => modalDefaultState()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
