import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Api } from "../service/Api";
import { useNavigate } from "react-router-dom";

const ComplexModal = () => {
  const [complexList, setComplexList] = useState([]);
  const [complex, setComplex] = useState("ganesh-glory-11");
  const [error, setError] = useState("");
  const history = useNavigate();

  const saveHandler = () => {
    if (complex && complex?.trim().length > 0) {
      history("/" + complex);
      return;
    }
    setError("Please select complex");
  };

  useEffect(() => {
    Api.getAllRestaurants().then((res) => {
      if (res && res?.data?.data?.length > 0) {
        setComplexList(res?.data?.data);
      }
    });
  }, []);

  return (
    <>
      <div className="relative w-full max-w-[850px] bg-white flex flex-col pt-3 pb-3 justify-center items-center mt-[1.75rem] rounded-lg shadow-lg border border-gray-200 mx-auto">
        <div className="absolute -top-[18px] left-[22px] bg-white rounded-md flex justify-start items-center px-2">
          <h1 className="text-xl font-bold p-1">Area</h1>
        </div>
        <div className="flex overflow-x-auto space-x-3 pt-4 px-2 sm:px-4 w-full scrollbar-hide justify-center align-middle">
          <Form className="w-full">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Select
                aria-label="Default select"
                onChange={(e) => setComplex(e.target.value)}
                value={complex}
              >
                <option value="" disabled>
                  Select Area
                </option>
                {complexList?.length > 0 &&
                  complexList.map((val) => (
                    <option value={val?.slug} selected={val?.slug === complex}>
                      {val?.brand_name}
                    </option>
                  ))}
              </Form.Select>
              {error && <p className="text-danger mt-2">{error}</p>}
            </Form.Group>
            <div className="itemDetails-button-container w-[100%] md:w-[50%]">
              <div
                onClick={saveHandler}
                className="itemDetails-add-btn max-w-none"
              >
                Save
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ComplexModal;
