import React, { useState } from "react";
import Modal from "react-modal";
import { RestaurantState } from "../context/Context";
import { useLocation, Link } from "react-router-dom";
import InstallPWA from "./InstallPWA";

const Footer = () => {
  const [isTC, setIsTC] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);
  const { state, dispatch } = RestaurantState();
  const location = useLocation();
  const currentRoute = location.pathname;
  const slug = "/" + localStorage.getItem("slug");

  function tc() {
    setIsTC(!isTC);
  }
  function contactus() {
    setIsContact(!isContact);
  }
  function returnpolicy() {
    setIsPolicy(!isPolicy);
  }

  return (
    <>
      {state.length === 0 || currentRoute !== slug ? (
        <footer>
          {/* <InstallPWA /> */}
          {/* <div
            className="flex items-center  justify-evenly  w-full h-[50px]"
            style={{ backgroundColor: "var(--primary-color)" }}
          >
            <div className="text-white flex items-center">
              <Link
                to="/about-us"
                style={{ color: "white", textDecoration: "none" }}
              >
                About Us
              </Link>
            </div>
          </div> */}
        </footer>
      ) : (
        ""
      )}
      <div className="bg-customBg w-full flex flex-col justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full max-w-[850px] p-3 gap-2 items-start">
          <div className="w-full text-center md:text-left">
            <h3 className="text-lg font-semibold my-1 md:text-left">General</h3>
            <ul className="space-y-2 text-sm text-customText p-0 m-0">
              <li className="hover:font-semibold hover:underline md:text-left">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="hover:font-semibold hover:underline md:text-left">
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
              <li className="hover:font-semibold hover:underline md:text-left">
                <Link to="/shipping-policy">Shipping & Delivery Policy</Link>
              </li>
              <li className="hover:font-semibold hover:underline md:text-left">
                <Link to="/refund-policy">Cancellation & Refund Policy</Link>
              </li>
              <li className="hover:font-semibold hover:underline md:text-left">
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>

          {/* Logo and Company Info */}
          <div className="flex flex-col items-center justify-center">
            <div className="items-center">
              <Link to={slug}>
                <img
                  src="/assets/single_cart_logo.png"
                  alt="Company Logo"
                  className="w-24 h-24 object-contain"
                />
              </Link>
            </div>
            <span className="text-lg font-semibold text-customText">
              SingleCart.in
            </span>
          </div>
        </div>
        <div className="bg-white my-2 w-full h-[1px]"></div>
        <div className="flex justify-between w-full text-sm text-customText max-w-[850px] mb-[1rem] px-3">
          <div>All rights reserved.</div>
          <div className="flex space-x-2 items-center">
            <a
              href="https://wa.me/919737372077"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                className="w-[35px] h-[35px]"
              />
            </a>
            <a
              href="https://www.instagram.com/singlecart.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                alt="Instagram"
                className="w-[30px] h-[30px]"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
