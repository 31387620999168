import axios from "axios";

const getHeaders = () => {
  let headers = {
    "ngrok-skip-browser-warning": "69420",
  };
  return headers;
};

const getAuthHeaders = (tag) => {
  let headers = {};
  const authToken = localStorage.getItem("access_token");
  const protectedMethods = [
    "v1/order",
    "v1/pay",
    "v1/user-panel/orders",
    "v1/profile-detail/",
  ];
  const isProtected = protectedMethods.find((v) => tag.includes(v));
  if (isProtected) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  return headers;
};
var baseUrl = "https://admin.singlecart.in";
// var baseUrl = "http://192.168.1.150:8000";

const callApi = (method, tag, data = {}) =>
  axios({
    method,
    url: tag,
    data,
    headers: {
      ...getHeaders(),
      ...getAuthHeaders(tag),
    },
  })
    .then((data) => data)
    .catch((error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem("mobile_no");
        localStorage.removeItem("access_token");
        setTimeout(() => {
          // window.location.href = "/";
        }, 2000)
      }
      return error.response;
    });

const Api = {
  getRestaurant: (slug) =>
    axios.get(`${baseUrl}/api/v1/get-restaurant/${slug}`),
  getMenu: (slug, data) =>
    axios.get(`${baseUrl}/api/v1/menu/${slug}`, {
      params: {
        kitchenId: data?.kitchenId,
        search: data?.search,
        sort: data?.sort,
      },
    }),
  getKitchens: (slug, data) =>
    callApi("GET", `${baseUrl}/api/v1/kitchenList/${slug}`),
  getFeturedItems: (slug, data) =>
    callApi("GET", `${baseUrl}/api/v1/featured-items/${slug}`),
  sendOTP: (data) =>
    callApi("POST", `${baseUrl}/api/v1/send-verification`, data),
  verifyOTP: (data) => callApi("POST", `${baseUrl}/api/v1/verify-code`, data),
  processCheckOut: (data) => callApi("POST", `${baseUrl}/api/v1/order`, data),

  processOnLine: (data) => callApi("POST", `${baseUrl}/api/v1/pay`, data),
  getPastOrders: (data) =>
    callApi("POST", `${baseUrl}/api/v1/user-panel/orders`, data),
  checkNumberExist: (slug, data) =>
    callApi("POST", `${baseUrl}/api/v1/check-number/${slug}`),
  verifyCustomer: (data) =>
    callApi("POST", `${baseUrl}/api/v1/verify-customer`, data),
  getProfileDetail: (slug, data) =>
    callApi("GET", `${baseUrl}/api/v1/profile-detail/${slug}`),
  updateCustomerProfile: (data) =>
    callApi("PATCH", `${baseUrl}/api/v1/update-customer`, data),
  updateOrderAndProceed: (data) =>
    callApi("PATCH", `${baseUrl}/api/v1/update-order`, data),
  updatePassword: (data) =>
    callApi("PATCH", `${baseUrl}/api/v1/update-password`, data),
  getBuildings: (slug, data) =>
    callApi("GET", `${baseUrl}/api/v1/get-buildings/${slug}`),
  getAllRestaurants: (slug, data) =>
    callApi("GET", `${baseUrl}/api/v1/get-all-restaurants`),
  getOrderConfig: (slug, data) =>
    callApi("POST", `${baseUrl}/api/v1/order-config`),
  getOrderDetails: (slug, data) =>
    callApi("POST", `${baseUrl}/api/v1/get-order-detail/${slug}`),
  updateOrderStatus: (slug, data) =>
    callApi("POST", `${baseUrl}/api/v1/orders/status-update/${slug}`, data),
  acceptOrderAsCash: (slug, data) =>
    callApi("POST", `${baseUrl}/api/v1/orders/order-cash-accept/${slug}`),
};

export { Api };
