import React from "react";
import "./cart.css";
import { useEffect, useState, useRef } from "react";
import { Api } from "../service/Api";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { format } from "date-fns";
import ReactGA from "react-ga4";

export const PastOrder = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [pastOrders, setPastOrder] = useState([]);
  const [total, setTotal] = useState();
  const [processingFees, setProcessingFees] = useState(0);
  const backURL = localStorage.getItem("slug")
    ? "/" + localStorage.getItem("slug")
    : "/";
  const orderStatuses = [
    "Initial",
    "Pending",
    "In-Progress",
    "Completed",
    "Cancelled",
  ];

  useEffect(() => {
    if (selectedOrderDetails) {
      const totalTaxAmount =
        Number(selectedOrderDetails.delivery_charge || 0) +
        Number(selectedOrderDetails.packaging_charge || 0) +
        Number(selectedOrderDetails.sub_total || 0);

      // Calculate processing fees (platform_fees is a percentage)
      const calculatedProcessingFees =
        (totalTaxAmount * Number(selectedOrderDetails.platform_fees || 0)) /
        100;

      setProcessingFees(calculatedProcessingFees.toFixed(2) ?? 0);

      let total =
        (Number(selectedOrderDetails.packaging_charge) ?? 0) +
        (Number(selectedOrderDetails.delivery_charge) ?? 0) +
        (Number(selectedOrderDetails.sub_total) ?? 0) +
        Number(calculatedProcessingFees ?? 0);
      setTotal(total.toFixed(2));
    }
  }, [selectedOrderDetails]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/pastOrders",
      title: "Past Orders",
    });
    const orderIds = localStorage.getItem("orderIds");
    const mobile_no = localStorage.getItem("mobile_no");
    const data = {
      mobile_no: mobile_no,
    };
    Api.getPastOrders(data).then((res) => {
      if (res.data.meta.code === 200) {
        setPastOrder(res.data.data);
      } else {
        console.error("something went wrong");
      }
    });
  }, []);

  const handleOverlayClick = (e) => {
    // Ensure the click is only registered on the background, not inside the modal content
    if (e.target.classList.contains("modal-overlay")) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (showModal) {
        setShowModal(false);
      }
    };

    // Push an initial state to history to trap the back button
    window.history.pushState(null, "");

    // Add the popstate event listener
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Remove the event listener on component unmount
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showModal]);

  return (
    <>
      <div className="mt-1 min-h-[90dvh] h-full overflow-y-auto w-full flex justify-center">
        <div className="cart d-flex flex-column max-h-[calc(95vh_-_135px)] overflow-y-auto px-2 md:px-4 py-2 w-full max-w-[850px]">
          <div className="flex items-center justify-between px-2 mb-4">
            <div className="flex-1">
              <div
                className="rounded h-[35px] w-[35px] pt-[1px] "
                style={{ backgroundColor: "var(--primary-color)" }}
              >
                <Link to={backURL}>
                  <FaArrowLeft color="black" fontSize="17px" className="m-2" />
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-center pt-[5px] text-lg font-bold">
              Past Orders
            </div>
            <div className="flex-1"></div>
          </div>
          {pastOrders.length > 0 ? (
            pastOrders.map((item) => (
              <div
                onClick={() => {
                  setShowModal(true);
                  setOrderDetails(item.order_details);
                  setSelectedOrderDetails(item);
                }}
                className="bg-white rounded-lg shadow-md p-3 mb-3 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                key={item.id}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-sm font-bold mb-1">
                      Restaurant:
                      <span className="text-gray-500 ml-2">
                        {item.relation_ship_restaurent_tbl?.brand_name}
                      </span>
                    </div>
                    <div className="text-sm font-bold">
                      Total Amount:
                      <span className="text-gray-500 ml-2">₹{item.total}</span>
                    </div>
                    <div className="text-sm font-bold">
                      Placed on:
                      <span className="text-gray-500 ml-2">
                        {item.created_at
                          ? format(new Date(item.created_at), "MMM d, h:mm a")
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`status-badge ${orderStatuses[
                      item?.status
                    ].toLowerCase()}`}
                  >
                    {orderStatuses[item?.status]}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mt-4 text-gray-500 dark:text-gray-400">
              No Data Found
            </div>
          )}
        </div>
      </div>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={(e) => handleOverlayClick(e)}
          >
            <div className="modal-content mx-1">
              <h2 className="modal-title font-bold mt-2">Order Details</h2>
              <div className="menu-items-list">
                {orderDetails.map((item) => (
                  <div className="menu-item shadow-md" key={item.id}>
                    <div className="grid grid-cols-5">
                      <div className="w-full max-w-[150px] h-[90px] shadow-md rounded-lg hover:shadow-lg focus:shadow-xl outline-none">
                        <img
                          className="w-full h-full cart-item-img object-cover"
                          src={item?.menu?.image || "/no-image.png"}
                          alt=""
                          onError={(e) => (e.target.src = "no-image.png")}
                        />
                      </div>
                      <div className="item-details col-span-3 ml-2 flex flex-col justify-center">
                        <div className="item-name-order text-left">
                          <span className="text-base font-semibold">
                            {item.item_name}
                          </span>
                        </div>
                        <div className="item-name-order text-left text-sm text-gray-500">
                          <span className="item-title">Shop :</span>
                          <span className="item-title-value">
                            {" "}
                            {item?.kitchen?.brand_name}
                          </span>
                        </div>

                        <div className="item-name-order text-left text-sm text-gray-500">
                          <span className="item-title">Qty :</span>
                          <span className="item-title-value">
                            {" "}
                            {item.quantity}
                          </span>
                        </div>
                      </div>
                      <div className="item-price leading-none">
                        ₹{item.sub_total}
                        {/* {item.packaging_charge && item.packaging_charge > 0 ? (
                          <span className="text-[12px] leading-tight items-center font-semibold">
                            {" "}
                            + ₹{parseFloat(item.packaging_charge).toFixed(2)}
                            <span className="text-[12px] text-customText leading-none">
                              {" "}
                              (Packaging Charges)
                            </span>
                          </span>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                ))}
                {total > 0 && (
                  <div className="bg-[#ffffff] rounded-lg shadow-md p-2 mt-2">
                    <div className="flex justify-content-between">
                      <div>Sub Total</div>
                      <div>{selectedOrderDetails.sub_total}</div>
                    </div>
                    {/*{selectedOrderDetails.delivery_charge &&*/}
                    {/*  selectedOrderDetails.delivery_charge > 0 && (*/}
                        <div className="flex justify-content-between">
                          <div>Delivery Charges</div>
                          <div>{selectedOrderDetails.delivery_charge ? selectedOrderDetails.delivery_charge : '0.00'}</div>
                        </div>
                      {/*)}*/}
                    {/*{selectedOrderDetails.packaging_charge &&*/}
                    {/*  selectedOrderDetails.packaging_charge > 0 && (*/}
                        <div className="flex justify-content-between">
                          <div>Packaging / Processing Fees</div>
                          <div>{selectedOrderDetails.packaging_charge ? selectedOrderDetails.packaging_charge : '0.00'}</div>
                        </div>
                      {/*)}*/}
                    {/*{processingFees && processingFees > 0 && (*/}
                      <div className="flex justify-content-between">
                        <div>Platform Fees</div>
                        <div>{processingFees ? processingFees : '0.00'}</div>
                      </div>
                    {/*)}*/}
                    {/* <div className="flex justify-content-between">
                    <div>Tax</div>
                    <div>0.00</div>
                  </div> */}
                    <div className="bg-[#e1e3ec] my-2 w-full h-[1px]"></div>
                    <div className="flex justify-content-between font-semibold">
                      <div>Total</div>
                      <div>₹{total}</div>
                    </div>
                  </div>
                )}
              </div>

              {/*footer*/}
              <div className="menu-modal-button-container">
                <button
                  className="btn-menu-modal-close"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
