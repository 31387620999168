import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { RestaurantState } from "../context/Context";
import "./menu.css";
import MenuBannerMain from "./MenuBanner/MenuBannerMain";
import ReactGA from "react-ga4"

const Menu = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/:slug", title: "Menu" });
    const storedArrayString = localStorage.getItem("cartState");
    dispatch({
      type: "SET_CART_ITEMS",
      payload: storedArrayString ? JSON.parse(storedArrayString) : [],
    });
}, [])
  const { state, dispatch } = RestaurantState();
  var totalQty = 0;
  var totalPrice = 0;
  for (let i = 0; i < state.length; i++) {
    totalQty = totalQty + state[i].quantity;
    totalPrice =
      parseInt(totalPrice) + parseInt(state[i].price * state[i].quantity);
  }
  return (
    <>
      <div className="menuBanner">
        <div className="px-2 sm:px-4 w-full">
          <MenuBannerMain totalQty={totalQty} />
          {state.length > 0 && (
            <div
              className="fixed left-0 w-100 bottom-0 flex justify-content-between py-[12px] border-t-[#e1e3ec] border-t-2"
              style={{ backgroundColor: "var(--primary-color)" }}
            >
              <div className="cartItem-reviewItem-left d-flex flex-column">
                <div className="cartItem-reviewItem-left-qty">
                  {totalQty} item's in CART
                </div>
                <div className="cartItem-reviewItem-left-price">
                  Total : ₹{totalPrice}
                </div>
              </div>
              <div className="cartItem-reviewItem-right">
                <Link to="/cart">
                  <span>View Cart</span>
                  <i className="fa-solid fa-caret-right"></i>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Menu;
