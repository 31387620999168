import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantState } from "../../context/Context";
import { Api } from "../../service/Api";
import MenuItemFilter from "./MenuBannerMain/MenuItemFilter";
import MenuItems from "./MenuBannerMain/MenuItems";
import Loader from "../Loader";
import { RiSearchLine } from "react-icons/ri";
import LazyLoad from "react-lazy-load";

const MenuBannerMain = () => {
  // const [kitchen, setKitchen] = useState([]);
  const [feturedItems, setFeturedItems] = useState([]);
  const [menu, setMenu] = useState([]);
  const [activeKitchen, setActiveKitchen] = useState();
  const { slug } = useParams();
  const {
    setRestaurentId,
    setTableId,
    state,
    dispatch,
    setTotalPackagingCharges,
    kitchen,
    setKitchen,
  } = RestaurantState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [sortOption, setSortOption] = useState();
  const [selectedKitchenImg, setSelectedKitchenImg] = useState("");
  const [selectedKitchenName, setSelectedKitchenName] = useState("");
  const [selectedKitchenPackCharge, setSelectedKitchenPackCharge] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    Api.getKitchens(slug).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        localStorage.setItem("slug", slug);
        setKitchen(res.data.data);
      }
    });

    Api.getFeturedItems(slug).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        localStorage.setItem("slug", slug);
        setFeturedItems(res.data.data);
      }
    });
  }, [slug]);

  useEffect(() => {
    if (!activeKitchen) return; // Skip initial call
    setLoading(true);
    const data = {
      kitchenId: activeKitchen,
      search: searchTerm, // Added search term for filtering
      sort: sortOption, // Added sort option for sorting
    };
    Api.getMenu(slug, data).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        setMenu(res.data.data[0].menu_items);
        setSelectedKitchenImg(res.data.data[0].brand_image);
        setSelectedKitchenName(res.data.data[0].name);
        setSelectedKitchenPackCharge(res.data.data[0].packaging_charge);
        // setActiveKitchen(res.data.data[0].id);
        setRestaurentId({
          type: "ADD_RESTAURENT",
          payload: res.data.data[0].restaurent_id,
        });
        setTableId({
          type: "ADD_TABLE",
          payload: 0,
        });
      }
      setLoading(false);
    });
  }, [activeKitchen, searchTerm, sortOption]);

  const handleOverlayClick = (e) => {
    // Ensure the click is only registered on the background, not inside the modal content
    if (e.target.classList.contains("modal-overlay")) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (isModalOpen) {
        setIsModalOpen(false);
      }
    };

    // Push an initial state to history to trap the back button
    window.history.pushState(null, "");

    // Add the popstate event listener
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Remove the event listener on component unmount
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isModalOpen]);

  const checkItemInCart = (itemId) => {
    return state.some((cartItem) => cartItem.id === itemId);
  };

  const getItemQuantity = (itemId) => {
    const foundItem = state.find((cartItem) => cartItem.id === itemId);
    return foundItem ? foundItem.quantity : 0;
  };

  const handleAddToCart = (item) => {
    const foundInCart = checkItemInCart(item.id);
    if (!foundInCart) {
      dispatch({
        type: "INCREASE_QTY",
        payload: { ...item, quantity: 1 },
      });
    }
  };

  const handleDecreaseQty = (item) => {
    dispatch({
      type: "DECREASE_QTY",
      payload: item,
    });
  };

  const handleIncreaseQty = (item) => {
    dispatch({
      type: "INCREASE_QTY",
      payload: item,
    });
  };

  const handleModalToggle = () => {
    setSearchTerm("");
    setSortOption("");
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {/* Search Bar */}

      {/* <div className="flex justify-center items-center mx-auto w-full max-w-[850px] my-3">
        <div className="flex justify-end items-center w-full">
          <div className="w-full sm:w-[350px] bg-white rounded-full shadow-md flex">
            Input Field
            <input
              type="text"
              placeholder="Search..."
              className="flex-1 px-4 py-2 text-customText bg-transparent rounded-full focus:outline-none"
            />
            Search Button
            <button
              className="w-8 h-8 bg-customBg rounded-full flex justify-center items-center shadow-lg m-1"
              aria-label="Search"
            >
              <RiSearchLine color="white" />
            </button>
          </div>
        </div>
      </div> */}

      {/* Featured Items Section */}
      <div className="relative w-full max-w-[850px] bg-white flex flex-col pt-3 pb-3 justify-center items-center mt-[2.50rem] rounded-lg shadow-lg border border-gray-200 mx-auto">
        {feturedItems.length > 0 && (
          <div className="absolute -top-[18px] left-[22px] bg-white rounded-md flex justify-start items-center px-2">
            <h1 className="text-xl font-bold p-1">Featured</h1>
          </div>
        )}
        <div className="flex overflow-x-auto space-x-3 pt-2 px-2 sm:px-4 w-full max-w-[850px] scrollbar-hide">
          {feturedItems.map((item, i) => (
            <MenuItems item={item} key={i} />
          ))}
        </div>
      </div>

      <div className="relative w-full max-w-[850px] bg-white pt-3 pb-3 justify-center items-center mt-[2.50rem] rounded-lg shadow-lg border border-gray-200 mx-auto mb-20">
        {/* Title Section */}
        <div className="absolute -top-[18px] left-[22px] bg-white rounded-md flex justify-start items-center px-2">
          <h1 className="text-xl font-bold p-1">Shops</h1>
        </div>

        {/* Kitchen Items Section */}
        <div className="flex justify-center align-middle pt-3">
          <div className="grid grid-cols-3 xs:grid-cols-4 sm:grid-cols-5 md:grid-cols-5 gap-[0.75rem] max-w-[900px] px-3 mx-auto">
            {kitchen.map((item, i) => (
              <MenuItemFilter
                item={item}
                key={i}
                setActiveKitchen={setActiveKitchen}
                activeKitchen={activeKitchen}
                setIsModalOpen={setIsModalOpen}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Modal for Menu items */}
      {isModalOpen && (
        <div
          className="modal-overlay w-full"
          onClick={(e) => handleOverlayClick(e)}
        >
          <div className="modal-content w-full max-w-[850px] m-2">
            <div className="flex items-center justify-between">
              <h2 className="modal-title text-lg font-bold">
                {selectedKitchenName ? selectedKitchenName : "Menu Items"}
              </h2>
              {/* Shop Logo */}
              {selectedKitchenImg && (
                <img
                  src={selectedKitchenImg}
                  alt="Shop Logo"
                  className="w-12 h-12 rounded-full"
                />
              )}
            </div>
            {selectedKitchenPackCharge && selectedKitchenPackCharge > 0 ? (
              <div className="text-[12px] w-full text-left leading-tight font-semibold">
                ₹{parseFloat(selectedKitchenPackCharge).toFixed(2)}
                <span className="text-[12px] text-customText leading-none">
                  {" "}
                  (Packaging / Processing fees apply to orders from this shop)
                </span>
              </div>
            ) : null}

            {/* Search and Sort Controls */}
            <div className="search-sort-container">
              {/* Search input */}
              <input
                type="text"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />

              {/* Sort Dropdown */}
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="sort-dropdown"
              >
                <option value="">Sort by</option>
                <option value="priceDesc">Price: High to Low</option>
                <option value="mostOrdered">Most Ordered</option>
                <option value="nameAsc">Name: A to Z</option>
                <option value="nameDesc">Name: Z to A</option>
              </select>
            </div>
            {/* Display menu items */}
            <div className="menu-items-list">
              {loading ? (
                <Loader />
              ) : menu.length > 0 ? (
                menu.map((item, index) => {
                  const qty = getItemQuantity(item.id);

                  return (
                    <div key={index} className="menu-item border shadow-md">
                      <div className="flex">
                        <div className="w-[120px] h-[90px]">
                          <LazyLoad className={`w-full h-[80px]`}>
                            <img
                              src={item?.image}
                              alt={item.item_name}
                              className="menu-item-image  w-full h-[80px] object-cover rounded-lg shadow-md"
                            />
                          </LazyLoad>
                        </div>
                        <div className="w-full ml-2 my-1 text-start flex flex-col justify-center">
                          <div className="items-start">
                            <div className="font-semibold text-[14px]">
                              {item.item_name}
                            </div>
                            {item.description && (
                              <div className="item-description">
                                <p>{item.description}</p>
                              </div>
                            )}
                          </div>
                          <div
                            className={`${
                              item.packaging_charge > 0 ? "sm:flex" : "flex"
                            } justify-between items-center`}
                          >
                            <div className="item-price leading-none w-full">
                              ₹
                              {qty > 0
                                ? parseFloat(item.price * qty).toFixed(2)
                                : item.price}
                              {/* {item.packaging_charge &&
                              item.packaging_charge > 0 ? (
                                <span className="text-[12px] leading-tight items-center font-semibold">
                                  {" "}
                                  + ₹
                                  {qty > 0
                                    ? parseFloat(
                                        item.packaging_charge * qty
                                      ).toFixed(2)
                                    : parseFloat(item.packaging_charge).toFixed(
                                        2
                                      )}
                                  <span className="text-[12px] text-customText leading-none">
                                    {" "}
                                    (Packaging Charges)
                                  </span>
                                </span>
                              ) : null} */}
                            </div>

                            <div className="itemDetails-button d-flex w-full items-end flex-column mt-auto ">
                              {qty === 0 ? (
                                <div className="itemDetails-button-container w-[100px]">
                                  <div
                                    onClick={() => handleAddToCart(item)}
                                    className="itemDetails-add-btn"
                                  >
                                    Add
                                  </div>
                                </div>
                              ) : (
                                <div className="itemDetails-qty-controls">
                                  <div
                                    onClick={() => handleDecreaseQty(item)}
                                    className="itemDetails-qty-button"
                                  >
                                    -
                                  </div>
                                  <div className="itemDetails-qty-display">
                                    {qty}
                                  </div>
                                  <div
                                    onClick={() => handleIncreaseQty(item)}
                                    className="itemDetails-qty-button"
                                  >
                                    +
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No items available.</p>
              )}
            </div>
            <div className="menu-modal-button-container">
              <button
                className="btn-menu-modal-close"
                onClick={handleModalToggle}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(MenuBannerMain);
