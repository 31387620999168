import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import {useParams} from "react-router-dom";
import {Api} from "../../service/Api";
// import QRCode from "qrcode.react";

const OrderDetailsWithQR = ({ orderId }) => {
  const { order_id} = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchOrderDetails = async () => {
    Api.getOrderDetails(order_id).then((response) => {
      if(response?.data?.meta?.code === 200){
        setOrder(response.data.data);
      } else {
        setError("Failed to fetch order details");
      }
      setLoading(false);
    }).catch(e => {
      console.log(e)
      setLoading(false);
      setError("Failed to fetch order details");
    });
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const handleCompleteOrder = () => {
    setLoading(true);
    Api.updateOrderStatus(order_id, {value: 3}).then((response) => {
      fetchOrderDetails();
      setLoading(false);
    }).catch(e => {
      console.log(e)
      setLoading(false);
    });
  }

  const handleCashPayment = () => {
    setLoading(true);
    Api.acceptOrderAsCash(order_id).then((response) => {
      fetchOrderDetails();
      setLoading(false);
    }).catch(e => {
      console.log(e)
      setLoading(false);
    });
  }

  let total = 410;
  let deliveryCharges = 50;
  let packagingCharges = 10;
  let status = "Pending";

  if (loading)
    return (
      <div className="text-center p-5">
        <Loader />
      </div>
    );
  if (error)
    return (
      <div className="text-center p-5 min-h-[40dvh] text-red-500">{error}</div>
    );

  return (
    <div className="mt-1 padding-bottom-3rem min-h-[90dvh] h-full overflow-y-auto w-full flex justify-center">
      <div className="cart d-flex flex-column max-h-[calc(95vh_-0px)] overflow-y-auto px-2 md:px-4 py-2 w-full max-w-[850px]">
        <div className="flex items-center justify-center pt-[5px] text-lg font-bold w-full mb-2">
          ORDER DETAILS
        </div>
        {/* QR Code */}
        <div className="flex justify-center w-full mb-3">
          <div className="rounded-2xl border-2 flex flex-col justify-center items-center border-customText bg-customText shadow-2xl mb-3">
            <div className="bg-[#ffffff] rounded-2xl border-2 border-customText">
              <img
                src={order?.qr_code_image}
                alt="qr-code"
                className="w-60 object-fill"
              />
            </div>
            {/* <QRCode value="https:ompany-payment.com" size={150} /> */}
          </div>
        </div>

        {/* Payment Status Section */}
        <p className="w-full text-end font-semibold mb-3">
          Payment Status:
          <span
            className={`ml-2 text-base py-1 px-2 shadow-md rounded-lg ${
              order?.payment_received === 0
                ? "text-red-600 bg-red-100" // Red color for Failure
                : "text-green-600 bg-green-100" // Green color for Captured
            }`}
          >
            {order?.payment_received === 1 ? 'Received' : 'Pending' }
          </span>
        </p>

        {/* Table Section */}
        <table className="w-full bg-white mb-3 rounded-lg shadow-md">
          <tbody>
            <tr>
              <td className="text-lg font-semibold pl-2 align-top">Name:</td>
              <td className="p-1">{order?.customer?.name}</td>
            </tr>
            <tr>
              <td className="text-lg font-semibold pl-2">Mobile:</td>
              <td className="p-1">{order?.customer?.mobile_number}</td>
            </tr>
            <tr>
              <td className="text-lg font-semibold pl-2 align-top">Address:</td>
              <td className="p-1">
                {order?.building?.name} - {order?.address}
              </td>
            </tr>
            <tr>
              <td className="text-lg font-semibold pl-2 align-top">Order status:</td>
              <td className="p-1">
                {order?.status_text}
              </td>
            </tr>
          </tbody>
        </table>

        {/* Ordered Items */}
        {order?.order_details ? (
          order?.order_details.map((item) => (
            <div
              className="bg-white rounded-lg shadow-md p-2 mb-3 cursor-pointer transition duration-300 ease-in-out hover:scale-105"
              key={item.id}
            >
              <div className="grid grid-cols-5">
                <div className="w-full max-w-[150px] h-[90px] shadow-md hover:shadow-lg focus:shadow-xl outline-none">
                  <img
                    className="w-full h-full cart-item-img object-cover"
                    src={item.image || "/no-image.png"}
                    alt="product-img"
                    onError={(e) => (e.target.src = "no-image.png")}
                  />
                </div>
                <div className="item-details col-span-3 ml-2 flex flex-col justify-center">
                  <div className="item-name-order text-left">
                    <span className="text-base font-semibold">
                      {item.item_name}
                    </span>
                  </div>
                  <div className="item-name-order text-left text-sm text-gray-500">
                    <span className="item-title">Shop :</span>
                    <span className="item-title-value">
                      {" "}
                      {item?.kitchen?.brand_name}
                    </span>
                  </div>

                  <div className="item-name-order text-left text-sm text-gray-500">
                    <span className="item-title">Qty :</span>
                    <span className="item-title-value"> {item.quantity}</span>
                  </div>
                </div>
                <div className="item-price text-center">₹{item.total}</div>
              </div>
            </div>
          ))
        ) : (
          <Loader />
        )}

        {/* Order Summary */}
        <div className="bg-[#ffffff] rounded-lg shadow-md p-2 mt-2">
          <div className="flex justify-content-between">
            <div>Sub Total</div>
            <div>{order.sub_total}</div>
          </div>
          {/*{order.delivery_charge > 0 && (*/}
            <div className="flex justify-content-between">
              <div>Delivery Charges</div>
              <div>{order.delivery_charge ? order.delivery_charge : '0.00'}</div>
            </div>
          {/*)}*/}
          {/*{order.packaging_charge > 0 && (*/}
            <div className="flex justify-content-between">
              <div>Packaging Charges</div>
              <div>{order.packaging_charge ? order.packaging_charge : '0.00'}</div>
            </div>
          {/*)}*/}
          <div className="flex justify-content-between">
            <div>Tax</div>
            <div>00</div>
          </div>
          {
            // order.platform_fees > 0 && (
              <div className="flex justify-content-between">
                <div>Platform Fees</div>
                <div>{order.platform_fees ? order.platform_fees : '0.00'}</div>
              </div>
            // )
          }
          <div className="bg-[#e1e3ec] my-2 w-full h-[1px]"></div>
          <div className="flex justify-content-between font-semibold">
            <div>Total</div>
            <div>₹{order.total}</div>
          </div>
        </div>

        {/* Complete Payment Button - Only Visible if Order is NOT Completed */}
        {[1,2].includes(order.status) && (
          <button
            type="button"
            className="w-full mt-4 bg-customBg rounded-lg px-5 py-2.5 text-center"
            onClick={handleCompleteOrder}
          >
            Complete the Delivery
          </button>
        )}

        {[1,2,3].includes(order.status) && (order?.payment_received == 0) && (
          <button
            type="button"
            className="w-full mt-4 bg-customBg rounded-lg px-5 py-2.5 text-center"
            onClick={handleCashPayment}
          >
            Accept as Cash
          </button>
        )}
      </div>
    </div>
  );
};

export default OrderDetailsWithQR;
