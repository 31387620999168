import React from "react";
import { checkTime } from "../../../utils/helper";
import LazyLoad from "react-lazy-load";

const MenuItemFilter = ({
  item,
  setActiveKitchen,
  activeKitchen,
  setIsModalOpen
}) => {
  const selected = "border-2 border-blue-500";
  return (
    <div
      className="w-full h-[145px] flex flex-col items-center justify-start cursor-pointer border border-gray-200 bg-white rounded-lg shadow-md"
      onClick={() => {
        setActiveKitchen(item.id);
        setIsModalOpen(true);
      }}
    >
      <LazyLoad className={`w-full h-[90px]`}>
      <img
        className={`w-full h-[90px] overflow-hidden border border-gray-200 rounded-t-xl shadow-md object-cover ${
          activeKitchen === item.id ? selected : ""
        }`}
        src={item.brand_image}
        alt=""
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/no-image.png";
        }}
      />
      </LazyLoad>
      <div className="relative group w-full">
        <div className="webkit-line-two px-2 py-1 text-start text-[14px] font-semibold overflow-hidden">
          {item.name}
        </div>
        <div className="absolute hidden shadow-md group-hover:block bg-customText text-gray-200 text-xs rounded py-1 px-2 -top-6 transform z-10 whitespace-nowrap">
          {item.name}
        </div>
      </div>
    </div>
  );
};

export default MenuItemFilter;
