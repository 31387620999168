import React, { useEffect } from "react";
import ReactGA from "react-ga4"

const PrivacyPolicy = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/privacy-policy", title: "Privacy Policy" });
    }, [])
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Privacy Policy</h1>
                <p style={styles.lastUpdated}>Last Updated: Dec 4th, 2024</p>
            </div>
            <div style={styles.content}>
                <section>
                    <p>
                        At SingleCart.in, we take your privacy seriously and are committed to
                        protecting your personal information. This Privacy Policy outlines how
                        we collect, use, and safeguard your data when you visit our website,
                        make purchases, or engage with our services. By accessing or using our
                        website, you agree to the terms of this Privacy Policy.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>1. Information We Collect</h2>
                    <p>
                        We collect information in several ways when you use our website:
                    </p>
                    <ul style={styles.list}>
                        <li>
                            <strong>Personal Information You Provide:</strong> When you place an order,
                            register an account, or sign up for our newsletter, we may collect
                            personal details such as name, email address, shipping address, billing
                            address, phone number, and payment information.
                        </li>
                        <li>
                            <strong>Information Collected Automatically:</strong> When you visit our
                            website, we may automatically collect certain information about your
                            device, usage, and interactions with our website through cookies and
                            similar technologies (e.g., IP address, browser type, pages visited).
                        </li>
                    </ul>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>2. How We Use Your Information</h2>
                    <p>
                        We use the information we collect for the following purposes:
                    </p>
                    <ul style={styles.list}>
                        <li>To process orders, including payment processing, shipping, and customer support.</li>
                        <li>To improve our services, products, and overall customer experience.</li>
                        <li>
                            To communicate with you through order confirmations, shipping updates,
                            newsletters, and promotions.
                        </li>
                        <li>To enhance security and detect fraudulent activities.</li>
                    </ul>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>3. Sharing Your Information</h2>
                    <p>
                        We do not sell, rent, or share your personal information with third
                        parties for marketing purposes. However, we may share your data with
                        trusted third-party service providers, such as:
                    </p>
                    <ul style={styles.list}>
                        <li>Payment processors to complete your transactions securely.</li>
                        <li>Shipping and delivery services to fulfill your orders.</li>
                        <li>
                            Marketing and analytics partners to help us improve our website and
                            communicate with you.
                        </li>
                    </ul>
                    <p>
                        All third-party providers are required to adhere to strict confidentiality
                        and data protection standards.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>4. Data Security</h2>
                    <p>
                        We use reasonable and appropriate measures to protect your personal
                        information from unauthorized access, alteration, disclosure, or
                        destruction. However, no data transmission over the Internet or
                        electronic storage can be guaranteed to be 100% secure, so we cannot
                        ensure absolute security.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>5. Cookies and Tracking Technologies</h2>
                    <p>
                        Our website uses cookies to enhance your browsing experience. Cookies
                        are small files that help us remember your preferences, track your
                        browsing patterns, and optimize our services. You can adjust your browser
                        settings to disable cookies, but this may affect the functionality of
                        some parts of our website.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>6. Your Rights and Choices</h2>
                    <p>You have the following rights regarding your personal information:</p>
                    <ul style={styles.list}>
                        <li>Access and correction of your personal data.</li>
                        <li>Request data deletion, subject to legal obligations.</li>
                        <li>Opt out of receiving promotional communications.</li>
                        <li>Control cookie settings through your browser or our website tool.</li>
                    </ul>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>7. Children’s Privacy</h2>
                    <p>
                        Our website is not intended for individuals under the age of 18, and we
                        do not knowingly collect or solicit personal information from children.
                        If we discover that we have collected personal information from a child
                        under 18, we will take steps to delete that information.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>8. Third-Party Links</h2>
                    <p>
                        Our website may contain links to third-party websites or services. This
                        Privacy Policy applies only to our website, and we are not responsible
                        for the privacy practices of third-party sites. We encourage you to review
                        their privacy policies.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>9. Changes to This Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be
                        posted on this page with the updated date. We encourage you to review this
                        Privacy Policy periodically to stay informed about how we are protecting
                        your information.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>10. Contact Us</h2>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our
                        privacy practices, please contact us:
                    </p>
                    <ul style={styles.list}>
                        <li>Email: info@singlecart.in</li>
                        <li>Address: Ahmedabad</li>
                    </ul>
                    <p>
                        By using our website, you acknowledge and agree to the terms of this
                        Privacy Policy.
                    </p>
                </section>
            </div>
        </div>
    );
};

// Inline Styles
const styles = {
    container: {
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        height: "90vh",
        overflowY: "scroll",
        paddingBottom: "3rem"
    },
    header: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
    },
    lastUpdated: {
        fontSize: "14px",
        color: "#555",
    },
    content: {
        lineHeight: "1.6",
    },
    sectionTitle: {
        fontSize: "20px",
        fontWeight: "600",
        marginTop: "20px",
        marginBottom: "10px",
    },
    list: {
        paddingLeft: "20px",
    },
};

export default PrivacyPolicy;