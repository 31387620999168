import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./revieworder.css";
import ServingTo from "./ReviewOrder/ServingTo";
import ServingDate from "./ReviewOrder/ServingDate";
import ItemDetailInstruction from "./ItemDetail/ItemDetailInstruction";
import Tips from "./ReviewOrder/Tips";
import TotalDetailItem from "./ReviewOrder/TotalDetailItem";
import ReviewOrderCart from "./ReviewOrder/ReviewOrderCart";
import ReactGA from "react-ga4"

const ReviewOrder = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/revieworder", title: "Review Order" });
}, [])
  return (
    <>
        <div className="reviewOrder d-flex flex-column">
          <div className="reviewOrder-detail">
            <div className="closeBtn d-flex justify-content-center align-items-center">
              <Link to="/cart">
                <i className="fa-sharp fa-solid fa-chevron-left"></i>
              </Link>
            </div>
            <div className="reviewOrder-servingTo">
              <div className="reviewOrder-servingTo-title">Serving to</div>
              <ServingTo />
            </div>
            <div className="reviewOrder-servingDate">
              <div className="reviewOrder-servingTo-title">Serving Date</div>
              <ServingDate />
              {/* <div className="reviewOrder-servingTo-cafe justify-content-between align-items-center">
                <div className="reviewOrder-servingTo-cafe-text">
                  VIP (applied)
                </div>
                <div className="reviewOrder-servingDate-btn">Remove</div>
              </div> */}
            </div>
            <div className="reviewOrder-instruction itemDetail-instruction">
              <ItemDetailInstruction />
            </div>
            <div className="reviewOrder-tips">
              <div className="reviewOrder-tips-title">Tips</div>
              <div className="reviewOrder-tips-per d-flex flex-row align-items-center m-3">
                <Tips />
              </div>
              <div className="reviewOrder-tips-text">
                <input
                  type="text"
                  maxLength="40"
                  placeholder="Tips"
                />
              </div>
            </div>
            <div className="reviewOrder-totalDetail d-flex flex-column">
              <TotalDetailItem />
              <div className="reviewOrder-totalDetail-total d-flex flex-row justify-content-between align-items-center">
                <div className="p-2">Total</div>
                <div className="p-2">AED 52</div>
              </div>
            </div>
          </div>
          <div className="reviewOrder-placeOrder row d-flex justify-content-between align-items-center">
            <ReviewOrderCart />
          </div>
        </div>
    </>
  );
};

export default ReviewOrder;
