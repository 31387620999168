import React from "react";
import { RestaurantState } from "../../../context/Context";
import { checkTime } from "../../../utils/helper";
import LazyLoad from "react-lazy-load";

const MenuItems = ({ item }) => {
  const selected = "border-2 border-blue-500";
  const { state, dispatch } = RestaurantState();
  var foundInCart = false;
  state.map((q, i) => {
    if (q.item_name === item.item_name && q.quantity > 0) {
      foundInCart = true;
    }
  });
  var qty = 0;
  state.forEach((a, i) => {
    if (a.id === item.id) {
      qty = a.quantity;
    }
  });
  return (
    <>
      <div className="w-[150px] max-h-[250px] bg-white rounded-lg shadow-md border p-2 border-gray-200 flex flex-col justify-center align-middle m-2">
        <LazyLoad className={`w-full h-[90px]`}>
        <img
          className={`w-full h-[90px] rounded-lg shadow-md object-cover`}
          src={item.image}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "no-image.png";
          }}
        />
        </LazyLoad>
        <div className="relative group pt-1">
          <div className="webkit-line-one text-start text-[14px] font-semibold">
            {item.item_name}
          </div>
          <div className="absolute hidden shadow-md group-hover:block bg-customText text-gray-200 text-xs rounded py-1 px-2 -top-6  transform z-10 whitespace-nowrap">
            {item.item_name}
          </div>
        </div>
        <div className="relative group">
          <div className="webkit-line-one text-[14px]">
            {" (" + item.kitchen.brand_name + ")"}
          </div>
          <div className="absolute hidden shadow-md group-hover:block bg-customText text-gray-200 text-xs rounded py-1 px-2 -top-6 transform z-10 whitespace-nowrap">
            {" (" + item.kitchen.brand_name + ")"}
          </div>
        </div>

        <div className="mt-2 text-[#28a745]">
          <span className="h-4 text-[16px] font-semibold">
            ₹{" "}
            {qty > 0
              ? parseFloat(item.price * qty).toFixed(2)
              : parseFloat(item.price).toFixed(2)}
          </span>
          {/* {item.packaging_charge && item.packaging_charge > 0 ? (
            <span className="text-[12px] leading-tight items-center font-semibold">
              {" "}
              + ₹{" "}
              {qty > 0
                ? parseFloat(item.packaging_charge * qty).toFixed(2)
                : parseFloat(item.packaging_charge).toFixed(2)}
              <div className="text-[10px] text-customText leading-none">
                {" "}
                (Packaging Charges)
              </div>
            </span>
          ) : null} */}
        </div>
        {!foundInCart && (
          <div className="itemDetails-button-container mt-[10px]">
            <div
              onClick={() => {
                dispatch({
                  type: "INCREASE_QTY",
                  payload: item,
                });
              }}
              className="itemDetails-add-btn max-w-none"
            >
              Add
            </div>
          </div>
        )}
        {foundInCart && (
          <>
            <div className="itemDetails-qty-controls mt-[10px]">
              <div
                className="itemDetails-qty-button"
                onClick={() => {
                  dispatch({
                    type: "DECREASE_QTY",
                    payload: item,
                  });
                }}
              >
                -
              </div>
              <div className="itemDetails-qty-display"> {qty}</div>
              <div
                className="itemDetails-qty-button"
                onClick={() => {
                  dispatch({
                    type: "INCREASE_QTY",
                    payload: item,
                  });
                }}
              >
                +
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MenuItems;
