import React, { useEffect } from "react";
import ReactGA from "react-ga4"

const ContactUS = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/contact-us", title: "Contact Us" });
    }, [])
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Contact Us</h1>
            </div>
            <div style={styles.content}>
                <section>
                    <p>We are always here to assist you!</p>
                </section>
                <section>
                    <p>
                        <b>Company Name : </b>SingleCart.in
                    </p>
                    <p>
                        <b>Email : </b>info@singlecart.in
                    </p>
                    <p>
                        <b>Phone : </b>+91-9737372077
                    </p>
                    <p>
                        <b>Working Hours : </b>9 AM to 9 PM
                    </p>
                </section>
                <section style={styles.socialSection}>
                    <p><b>Follow Us:</b></p>
                    <a
                        href="https://www.instagram.com/singlecart.in/"
                        style={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                            alt="Instagram"
                            style={styles.icon}
                        />
                        Instagram
                    </a>
                    <a
                        href="https://wa.me/919737372077"
                        style={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                            alt="WhatsApp"
                            style={styles.icon}
                        />
                        WhatsApp
                    </a>
                </section>
            </div>
        </div>
    );
};

// Inline Styles
const styles = {
    container: {
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        height: "90vh",
        overflowY: "scroll",
        paddingBottom: "3rem"
    },
    header: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
    },
    content: {
        lineHeight: "1.6",
    },
    socialSection: {
        marginTop: "20px",
    },
    link: {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "#007BFF",
        marginBottom: "10px",
    },
    icon: {
        width: "45px",
        height: "45px",
        marginRight: "10px",
        margin:"5px",
    },
};

export default ContactUS;