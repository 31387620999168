import React from "react";
import { RestaurantState } from "../context/Context";
import { Api } from "../service/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HomeBanner from "./HomeBanner";
import ComplexModal from "./ComplexModal";
import ReactGA from "react-ga4"

const Home = () => {
  const history = useNavigate();
  //  const [kitchens, setKitchens] = useState([]);
  const { slug } = useParams();
  const { state, dispatch, setRestaurentId, setTableId } = RestaurantState();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
    history("/ganesh-glory-11");
    // Api.getMenu(slug).then((res) => {
    //   if (res.data.meta.code === 301) {
    //     history("/" + res.data.meta.url);
    //   }
    //   if (res.data.meta.code === 200) {
    //     // setKitchens(res.data.data.kitchens);
    //     setRestaurentId({
    //       type: "ADD_RESTAURENT",
    //       payload: res.data.data.id,
    //     });
    //     setTableId({
    //       type: "ADD_TABLE",
    //       payload: 0,
    //     });
    //   }
    // });
  }, [history]);

  return (
    <>
      {/* <div className="kitchens">
        {kitchens && kitchens.length > 0 ? (
          kitchens.map((q, i) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{q.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {q.rel_menu_items.map((q, i) => {
                    let qty = 0;
                    state.forEach((a, i) => {
                      if (a.id === q.id) {
                        qty = a.quantity;
                      }
                    });
                    return (
                      <ListGroup>
                        <ListGroup.Item key={q.id}>
                          <Row>
                            <Col sm={12} md={4}>
                              <Image
                                src="https://loremflickr.com/100/40"
                                alt={q.name}
                                fluid
                                rounded
                              />
                            </Col>
                            <Col sm={12} md={4}>
                              <span>{q.name}</span>
                            </Col>
                            <Col sm={1} md={2}>
                              ₹ {q.price}
                            </Col>
                            <Col sm={1} md={2}>
                              <div>
                                <div className="number">
                                  <Button
                                    className="minus"
                                    onClick={() => {
                                      dispatch({
                                        type: "DECREASE_QTY",
                                        payload: q,
                                      });
                                    }}
                                  >
                                    -
                                  </Button>
                                  <input type="text" value={qty} disabled />
                                  <Button
                                    className="plus"
                                    onClick={() => {
                                      dispatch({
                                        type: "INCREASE_QTY",
                                        payload: q,
                                      });
                                    }}
                                  >
                                    +
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Loader />
        )}
      </div> */}

      {/* <HomeBanner /> */}
      <ComplexModal />

      {/* typeof slug === "string" && (
      <div className="kitchens">
        {kitchens && kitchens.length > 0 ? (
          kitchens.map((q, i) => {
            return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{q.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {q.rel_menu_items.map((q, i) => {
                      let qty = 0;
                      state.forEach((a, i) => {
                        if (a.id === q.id) {
                          qty = a.quantity;
                        }
                      });
                      return (
                        <ListGroup>
                          <ListGroup.Item key={q.id}>
                            <Row>
                              <Col sm={12} md={4}>
                                <Image
                                  src="https://loremflickr.com/100/40"
                                  alt={q.name}
                                  fluid
                                  rounded
                                />
                              </Col>
                              <Col sm={12} md={4}>
                                <span>{q.name}</span>
                              </Col>
                              <Col sm={1} md={2}>
                                ₹ {q.price}
                              </Col>
                              <Col sm={1} md={2}>
                                <div>
                                  <div className="number">
                                    <Button
                                      className="minus"
                                      onClick={() => {
                                        dispatch({
                                          type: "DECREASE_QTY",
                                          payload: q,
                                        });
                                      }}
                                    >
                                      -
                                    </Button>
                                    <input type="text" value={qty} disabled />
                                    <Button
                                      className="plus"
                                      onClick={() => {
                                        dispatch({
                                          type: "INCREASE_QTY",
                                          payload: q,
                                        });
                                      }}
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>

            );
          })
        ) : (
          <Loader />
        )}
      </div>
    ) */}
    </>
  );
};

export default Home;
